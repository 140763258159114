import { render, staticRenderFns } from "./startMoney.vue?vue&type=template&id=0c12cddd&scoped=true&"
import script from "./startMoney.vue?vue&type=script&lang=js&"
export * from "./startMoney.vue?vue&type=script&lang=js&"
import style0 from "./startMoney.vue?vue&type=style&index=0&id=0c12cddd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c12cddd",
  null
  
)

export default component.exports