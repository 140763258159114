<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto;">
                <el-button type="primary" @click="searchFun">新增期初余额</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" align="center" label="项目编号" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="项目名称" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="研发形式" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="项目启动日期" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button class="mr10" style="color:#F56C6C" type="text" @click="showUpdateModel(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="80%" center>
            <el-table :data="setTitleData" :span-method="arraySpanMethod" border style="width: 100%">
                <el-table-column prop="title1" label="一级">
                </el-table-column>
                <el-table-column prop="title2" label="二级">
                </el-table-column>
                <el-table-column prop="title3" label="三级" width="120">
                </el-table-column>
                <el-table-column align="center" label="金额" width="150">
                    <template slot-scope="scope">
                        <el-input v-model="formData[scope.row.key]" placeholder="输入金额" type="number"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "userTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [{ name: "测试" }],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {

            },
            rules: {
                levelName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入等级名称",
                },
                levelSort: {
                    required: true,
                    trigger: "blur",
                    message: "请输入升级顺序",
                },
            },
            //编辑配置
            formType: "",
            setTitleData: [{
                title1: '一、人员人工费用小计',
                title2: '直接从事研发活动人员',
                title3: '工资薪金',
                key: 'money'
            },
            {
                title1: '',
                title2: '',
                title3: '五险一金',
                key: 'money'
            },
            {
                title1: '',
                title2: '外聘研发人员劳务费用',
                title3: '',
                key: 'money'
            },
            {
                title1: '二、直接投入费用小计',
                title2: '研发活动直接消耗',
                title3: '材料',
                key: 'money'
            },
            {
                title1: '',
                title2: '',
                title3: '燃料',
                key: 'money'
            },
            {
                title1: '',
                title2: '',
                title3: '动力费用',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于中间试验和产品试制的模具、工艺装备开发及制造费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于不构成固定资产的样品、样机及一般测试手段购置费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于试制产品的检验费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于研发活动的仪器、设备的运行维护、调整、检验、维修等费用',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '通过经营租赁方式租入的用于研发活动的仪器、设备租赁费',
                title3: '',
                key: 'money'
            },
            {
                title1: '三、折旧费用',
                title2: '用于研发活动的仪器的折旧费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于研发活动的设备的折旧费',
                title3: '',
                key: 'money'
            },
            {
                title1: '四、无形资产摊销',
                title2: '用于研发活动的软件的摊销费用',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于研发活动的软件的摊销费用',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '用于研发活动的非专利技术（包括许可证、专有技术、设计和计算方法等）的摊销费用',
                title3: '',
                key: 'money'
            },

            {
                title1: '五、新产品设计费小计',
                title2: '新产品设计费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '新工艺规程制定费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '新药研制的临床试验费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '勘探开发技术的现场试验费',
                title3: '',
                key: 'money'
            },

            {
                title1: '六、其他相关费用小计',
                title2: '技术图书资料费、资料翻译费、专家咨询费、高新科技研发保险费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '研发成果的检索、分析、评议、论证、鉴定、评审、验收费用',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '知识产权的申请费、注册费、代理费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '差旅费、会议费',
                title3: '',
                key: 'money'
            },
            {
                title1: '',
                title2: '其他费用',
                title3: '',
                key: 'money'
            },

            {
                title1: '七、委托外部机构或个人进行研发活动所发生的费用',
                title2: '',
                title3: '',
                key: 'money'
            },
            ],
            updateId: 0,
        };
    },
    filters: {

    },
    created() {

    },
    methods: {
        /**@method 搜索 */
        searchFun() { },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            let hideArr = [//删除行/列
                [1, 0], [1, 1], [2, 2], [2, 0], [4, 0], [5, 0], [4, 1], [5, 1],
                [6, 0], [6, 2], [7, 0], [7, 2], [8, 0], [9, 0], [10, 0], [8, 2], [9, 2], [10, 2],
                [12, 0], [11, 2], [12, 2],
                [14, 0], [15, 0], [13, 2], [14, 2], [15, 2],
                [17, 0], [18, 0], [19, 0], [16, 2], [17, 2], [18, 2], [19, 2],
                [21, 0], [22, 0], [23, 0], [24, 0], [20, 2], [21, 2], [22, 2], [23, 2], [24, 2],
                [25, 1], [25, 2],
            ];
            let rowCol12 = [//合并行
                [2, 1], [6, 1], [7, 1], [8, 1], [9, 1], [10, 1],
                [11, 1], [12, 1], [13, 1], [14, 1], [15, 1],
                [11, 1], [12, 1],
                [13, 1], [14, 1], [15, 1],
                [16, 1], [17, 1], [18, 1], [19, 1],
                [20, 1], [21, 1], [22, 1], [23, 1], [24, 1]
            ];
            let rowColAll = [
                [0, 0, 3, 1],
                [0, 1, 2, 1],
                [3, 0, 8, 1],
                [3, 1, 3, 1],
                [11, 0, 2, 1],
                [13, 0, 3, 1],
                [16, 0, 4, 1],
                [20, 0, 5, 1],
                [25, 0, 1, 3],
            ];
            for (let row of hideArr) {
                if (rowIndex == row[0] && columnIndex == row[1]) {
                    return [0, 0];
                }
            }
            for (let row of rowCol12) {
                if (rowIndex == row[0] && columnIndex == row[1]) {
                    return [1, 2];
                }
            }
            for (let row of rowColAll) {
                if (rowIndex == row[0] && columnIndex == row[1]) {
                    return [row[2], row[3]];
                }
            }

        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {

                }
            });
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage
            };
            try {
                this.loading = true;

                this.loading = false;

                // const { data } = result;
                // this.tableData = data.pageInfo.list;
                // this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增...";
            this.formType = "add";
            this.formData = {

            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑...";
            this.formType = "update";
            this.updateId = val.id;
            this.formData = {

            };
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>